.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.header_img_size {
  max-width: 60px;
}

.k-master-row {
  font-size: 14px;
}

.k-virtual-scrollable-wrap td {
  font-size: 14px;
  white-space: nowrap;
  line-height: 13px;
}

#grid .k-virtual-scrollable-wrap tr td {
  height: 15px
}